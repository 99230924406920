body {
  font-family: 'Arial', sans-serif;
  background-color: #f0f2f5;
  margin: 0;
  padding: 0;
  min-height: 100vh; /* Full height of the viewport */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Container for the main form */
.checkout-page-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
  max-width: 1200px;
  padding: 20px;
  background-color: #cac9c9;
  border-radius: 16px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
  position: relative;
  height: auto;
  align-items: flex-start;
}



.checkout-content {
  flex: 1;
  background-color: #ffffff; /* White background for inner content */
  padding: 30px; /* Add padding for the inner content */
  border-radius: 16px; /* Rounded corners for better appearance */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1); /* Soft shadow for better depth */
  width: 500px; /* Fixed width to maintain consistency across all options */
  max-width: 100%;
  margin: 0 auto; /* Center the content */
}

.checkout-page-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
  max-width: 1200px;
  margin: 40px auto;
  padding: 20px;
  background-color: #cac9c9;
  border-radius: 16px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
  position: relative;
  height: auto;
  align-items: flex-start;
}

.sidebar {
  width: 170px;
  padding: 20px;
  border-right: 2px solid #e0e0e0;
  display: flex;
  flex-direction: column;
  align-self: stretch;
}

.sidebar button {
  width: 100%;
  padding: 12px 15px;
  margin: 0;
  border: none;
  background-color: transparent;
  text-align: left;
  font-size: 1rem;
  color: #444;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
}

.sidebar button.active {
  background-color: #e0f7ea;
  color: #2e7d32;
}

.sidebar button:hover {
  background-color: #f0f0f0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.sidebar button.active {
  background-color: #e0f7ea;
  color: #007bff;
  box-shadow: 0 4px 8px rgba(46, 125, 50, 0.2);
}

.sidebar hr {
  border: 0;
  border-top: 1px solid #e0e0e0; /* Light gray color */
  margin: 10px 0; /* Spacing around the line */
}

.sidebar-title {
  font-weight: bold;
  margin-bottom: 20px;
  color: #444;
  font-size: 1.1rem;
}

/* Styling for the logo */
.logo {
  position: absolute;
  top: -80px;
  left: 50%;
  transform: translateX(-50%);
  background-color: transparent;
  border-radius: 50%;
  padding: 20px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
}

.logo img {
  width: 100px;
  height: 100px;
}

.credit-card-preview {
  background: linear-gradient(to bottom right, #56ccf2, #2f80ed);
  border-radius: 11px; /* Reduced by 30% */
  color: white;
  padding: 14px; /* Reduced by 30% */
  margin-bottom: 21px; /* Reduced by 30% */
  text-align: left;
  box-shadow: 0 5.6px 11.2px rgba(0, 0, 0, 0.15); /* Reduced by 30% */
  position: relative;
  width: 56%; /* Reduced by 30% */
  height: 140px; /* Reduced by 30% */
}

/* Styling for the bank title on the card */
.credit-card-preview .bank {
  text-align: center;
  font-size: 1.05rem; /* Reduced by 30% from 1.5rem */
  font-weight: bold;
  margin-bottom: 7px; /* Reduced by 30% */
}

/* Styling for the chip image on the card */
.credit-card-preview .chip {
  width: 35px; /* Reduced by 30% */
  height: 24.5px; /* Reduced by 30% */
  background-color: #f5f5f5;
  border-radius: 4px; /* Reduced by 30% */
  position: absolute;
  top: 14px; /* Reduced by 30% */
  left: 14px; /* Reduced by 30% */
}

/* Styling for the card number on the card */
.credit-card-preview .card-number {
  font-size: 0.98rem; /* Reduced by 30% from 1.4rem */
  letter-spacing: 2.8px; /* Reduced by 30% */
  text-align: center;
  position: absolute;
  top: 77px; /* Reduced by 30% */
  left: 50%;
  transform: translateX(-50%);
}

/* Styling for the expiry and CVV information on the card */
.credit-card-preview .expiry-cvv {
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 28px; /* Reduced by 30% */
  left: 14px; /* Reduced by 30% */
  right: 14px; /* Reduced by 30% */
}

.credit-card-preview .expiry-date {
  font-size: 0.7rem; /* Reduced by 30% from 1rem */
}

.credit-card-preview .cvv {
  font-size: 0.7rem; /* Reduced by 30% from 1rem */
  text-align: right;
}

/* Styling for the cardholder name */
.credit-card-preview .cardholder-name {
  font-size: 0.7rem; /* Reduced by 30% from 1rem */
  position: absolute;
  bottom: 5px; /* Reduced by 30% */
}

/* Card details form styling */
form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 350px;
  margin: 20px auto 0;
}

label {
  text-align: left;
  font-weight: bold;
  margin-bottom: 5px;
}

input {
  padding: 14px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 1rem;
  transition: border-color 0.3s;
  background-color: #f9f9f9;
  width: calc(100% - 20px);
  margin: 0 auto;
  max-width: 574px;
}

input:focus {
  border-color: #007bff;
  outline: none;
}

.form-group {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.form-group > div {
  flex: 1;
  max-width: 400px;
}

button {
  padding: 15px;
  background-color: #2f80ed;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  width: 80%;
  margin: 10px 1%;
  max-width: 400px;
  align-self: center;
}

button:hover {
  background-color: #1c5bbf;
  transform: translateY(-2px);
}

.cancel-button {
  background-color: #e0e0e0;
  color: #333;
  transition: background-color 0.3s;
  width: 80%;
  margin: 10px 1%;
}

.cancel-button:hover {
  background-color: #b0b0b0;
}

/* Loading spinner styling */
.loading {
  font-size: 1.5rem;
  font-weight: bold;
  color: #007bff;
}

/* Success and Failure page styling */
.success-container,
.failure-container {
  background-color: #ffffff;
  padding: 40px;
  border-radius: 16px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
  max-width: 500px;
  width: 100%;
  text-align: center;
  border: 1px solid #e0e0e0;
}

/* Success container styling */
.success-container h1 {
  color: #28a745;
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.success-icon {
  font-size: 6rem;
  color: #28a745;
  margin-bottom: 20px;
}

/* Failure container styling */
.failure-container h1 {
  color: #dc3545;
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.failure-icon {
  font-size: 6rem;
  color: #dc3545;
  margin-bottom: 20px;
}

.retry-button,
.cancel-button {
  padding: 12px 30px;
  margin: 7px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1.1rem;
  transition: background-color 0.3s, transform 0.2s;
}

.retry-button {
  background-color: #007bff;
  color: white;
}

.cancel-button {
  background-color: #6c757d;
  color: white;
}

.retry-button:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}

.cancel-button:hover {
  background-color: #5a6268;
  transform: translateY(-2px);
}

.powered-by {
  font-size: 0.8rem;
  margin-top: 20px;
  color: #888;
  text-align: center;
  position: relative;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5); /* Semi-transparent overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensures it stays on top of the content */
}

/* Styling for the transfer details box */
.transfer-details-box {
  border: 2px solid #007bff;
  padding: 20px;
  border-radius: 12px;
  margin-bottom: 20px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.transfer-details-box .title {
  margin-top: 0;
  justify-self: center;
}

/* Styling for the time left text */
.time-left {
  font-size: 1.2rem;
  margin-bottom: 15px;
  font-weight: bold;
}

.time-left.critical {
  color: red;
}

.icon {
  width: 20px;
  height: 20px;
  margin-right: 10px; /* Add some spacing between the icon and the text */
}

.payment-done-button {
  padding: 15px;
  background-color: #2f80ed;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  width: 80%;
  max-width: 400px;
  margin: 20px auto 0; /* Centers the button */
  display: block;
}

.payment-done-button:hover {
  background-color: #1c5bbf;
  transform: translateY(-2px);
}

.disabled-button {
  background-color: #d3d3d3; /* Light gray to indicate disabled */
  cursor: not-allowed; /* Show that the button cannot be clicked */
}

button:disabled {
  opacity: 0.6;
}

.download-receipt-button {
  padding: 12px 20px;
  background-color: #007bff;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  margin-top: 20px;
}

.download-receipt-button:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}

.download-receipt-button:active {
  background-color: #004494;
  transform: translateY(0);
}

.download-receipt-button:focus {
  outline: none;
  box-shadow: 0 0 0 4px rgba(0, 123, 255, 0.3);
}

.payment-summary {
  font-size: 1.3rem;
  color: #444;
  text-align: right;
  margin-left: auto;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: bold;
}

.payment-summary p {
  margin: 0;
  line-height: 1.4;
}

.headerCheckout {
  display: flex;
  align-items: center;
  border-radius: 8px
}

.card-payment-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 250px; /* Set a reduced width */
  padding: 15px; /* Adjust padding */
  justify-self: center;
}

.card-payment-container .credit-card-preview {
  width: 70%; /* Full width within reduced container */
  height: 70px; /* Reduce height by 50% */
  border-radius: 8px; /* Half the original border radius */
  font-size: 0.75rem; /* Adjust font size */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adjust shadow */
}

.card-payment-container .bank {
  font-size: 0.75rem;
  margin-bottom: 3px; /* Adjust spacing */
}

.card-payment-container .chip {
  width: 17.5px; /* Half width */
  height: 12.25px; /* Half height */
  top: 7px; /* Adjust position */
  left: 7px;
}

.card-payment-container .card-number {
  font-size: 0.5rem;
  top: 38px;
  left: 50%;
  transform: translateX(-50%);
}

.card-payment-container .expiry-cvv {
  bottom: 14px;
  left: 7px;
  right: 7px;
}

.card-payment-container .expiry-date,
.card-payment-container .cvv,
.card-payment-container {
  font-size: 0.5rem;
  margin: 0;
}

.credit-card-preview .cardholder-name {
  font-size: 0.5rem;
  text-align: left;
  margin-top: 5px; /* Add some space above the name */
  margin-left: -7px;
}

.card-payment-container form {
  width: 100%; /* Make form width fit within reduced container */
}

.card-payment-container input {
  font-size: 1rem; /* Keep input text size consistent */
  padding: 7px; /* Adjust padding */
  border-radius: 4px; /* Adjust border radius */
}

.pay-by-phone-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.phone-input {
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 1rem;
  width: 100%;
  max-width: 300px;
  text-align: center;
}

.error-message {
  color: red;
  font-size: 0.9rem;
  margin-top: -5px;
}

.pay-now-button {
  padding: 12px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.pay-now-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.payment-done-button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.payment-done-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.spinners-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50%;
}

.spinners {
  display: inline-block;
  width: 10px;
  height: 10px;
  border: 2px solid #ffffff; /* Light border */
  border-top: 2px solid #007bff; /* Active color */
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

/* Spinner animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}



