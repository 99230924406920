.receipt-content {
    padding-top: 40px;
    overflow: hidden;
}

.header {
    border-top: 3px double blue;
    border-bottom: 3px double blue;
    text-align: center;
    margin-bottom: 20px;
    padding: 5px 0;
    font-weight: bold;
    font-size: 1.5em;
    color: #1C78B9;
}

.company-date {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 10px;
    position: relative;
}

.company-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.company-title {

}

.company-subtitle {
    font-size: 0.8em;
    color: #555;
    margin-top: 5px;
}

.separator {
    position: relative;
    height: 3px;
    margin: 10px 0 20px 0;
}

.separator::before,
.separator::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: blue;
}

.separator::after {
    top: 3px;
}

.fields .field {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid black;
    padding: 10px 0;
}

.field:first-of-type {
    border-top: 2px solid black;
}

.sum-field {
    margin-bottom: 2px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
}

.sum-label,
.sum-value {
    color: #1C78B9;
    font-weight: bold;
    font-size: 1.2rem;
}

.sum-label {
    /* Additional styles for the label if needed */
}

.sum-value {
    /* Additional styles for the value if needed */
}

.fields .field:first-child {
    border-top: 1px solid black;
}

.thank-you {
    text-align: center;
    font-size: 1.2rem;
    color: #1B78B9;
    margin-bottom: 30px;
    font-weight: 500;
    letter-spacing: 0.03rem;
}

.footer {
    padding: 20px 0;
    width: calc(100% + 48px);
    margin-left: -24px;
    background-color: #DDDDDD;
    color: black;
    text-align: center;
    box-sizing: border-box;
    position: relative;
}

.transaction-type {
    text-align: center;
    font-size: 1.2rem;
    color: #1B78B9;
    margin-bottom: 30px;
    font-weight: 500;
    margin-top: 40px;
}

.footer::before {
    content: "";
    position: absolute;
    display: block;
    height: 10px;
    top: -10px;  /* This is to position it above the footer */
    left: 0;
    right: 0;
    background: linear-gradient(45deg, transparent 33.333%, #DDDDDD 33.333%, #DDDDDD 66.667%, transparent 66.667%), linear-gradient(-45deg, transparent 33.333%, #DDDDDD 33.333%, #DDDDDD 66.667%, transparent 66.667%);
    background-size: 15px 30px;
    background-position: 0 0;
}

.dialog-root {
    overflow-x: hidden;
    text-align: center;
}

.error-message {
    padding: 20px;
}

.error-title {
    text-align: center;
    font-weight: bold;
    font-size: 2rem;
    color: red;
    opacity: 0.5;
}

.detail {
    color: #1C78B9;
    font-size: 1rem;
    margin: 0.5rem 0;
}

.details {
    margin-top: 0.5rem;
}

.detail.name {
    font-weight: bold;
    font-size: 0.9rem;
    color: #1C78B9;
}

.detail.address{
    font-size: 0.7rem;
    color: #1C78B9;
}

.detail.phone {
    font-weight: bold;
    font-size: 0.7rem;
    color: #1C78B9;
}

.detail.vat {
    font-weight: bold;
    font-size: 0.7rem;
    color: #1C78B9;
}

.products-section {
    margin-top: 30px;
}

.products-section .product-header, .products-section .product-detail {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid black;
    padding: 10px 0;
    font-size: 13px;
}

.product-name, .product-qty, .product-price, .product-total {
    flex-basis: 25%;
    text-align: center;
}

.product-not-available {
    text-align: center;
    flex-basis: 100%;
}

.products-section .product-detail:last-child {
    border-bottom: none;
}

.discount-row {
    border-bottom: none;
}

.vat-field {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin-top: 5px;
}

.vat-label {
    font-size: 13px;
    color: #1C78B9; /* This was the color of the SUM text */
    font-weight: normal;
}

.vat-value {
    font-size: 13px;
    color: #1C78B9; /* This was the color of the SUM value */
}

.receipt-number {
    text-align: center;
    font-size: 14px;
    color: #1C78B9;
    padding: 5px 0;
}

.card-details,
.bank-transfer-details {
    margin-top: 20px;
    padding: 10px 15px;
    border: 1px solid #1C78B9;
    border-radius: 8px;
    background-color: #f7f9fc;
    margin-bottom: 30px;
}

.card-details h3,
.bank-transfer-details h3 {
    color: #1C78B9;
    font-size: 1.1rem;
    font-weight: bold;
    margin-bottom: 8px;
    border-bottom: 1px solid #1C78B9;
    padding-bottom: 5px;
    text-align: center;
}

.card-details p,
.bank-transfer-details p {
    font-size: 0.9rem;
    color: #333;
    margin: 5px 0;
    display: flex;
    justify-content: space-between;
}

.card-details .label,
.bank-transfer-details .label {
    font-weight: bold;
    color: #1C78B9;
}

.card-details .value,
.bank-transfer-details .value {
    color: #555;
}

.download-button {
    margin: 20px auto;
    padding: 10px 20px;
    background-color: #1C78B9;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s;
}

.download-button:hover {
    background-color: #155d8c;
}

.payment-confirmation {
    background-color: #1C78B9;
    color: white;
    font-weight: bold;
    font-size: 1.2rem;
    text-align: center;
    padding: 15px;
    border-radius: 5px;
    margin: 20px 0;
}

.payment-amount {
    display: block;
    font-size: 1.8rem; /* Larger font size for emphasis */
    margin-top: 10px;
}



